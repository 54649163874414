<template>
  <div class="my-8 overflow-x-scroll min-h-screen">
    <div class="align-middle inline-block min-w-full border-b border-gray-200">
      <div
        v-if="newButton && newButton.label && can(`edit_${newButton.label.toLowerCase()}`)"
        class="flex justify-end px-6"
      >
        <data-table-actions :options="options" @event="event" :enabled="!selections.length" />
      </div>
      <table class="min-w-full">
        <thead>
          <tr class="border-t border-gray-200">
            <th
              v-for="(column, col_index) in columns"
              :key="`column-${col_index}`"
              :width="column.width"
              :class="column.align"
              class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <template v-if="column.type === 'check'">
                <input
                  type="checkbox"
                  name="select-all"
                  class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  v-model="checked"
                />
              </template>
              <template>
                {{ column.label }}
              </template>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <tr
            v-for="(row, row_index) in rows"
            :key="`row-${row_index}`"
            :class="{ 'bg-gray-50': row_index % 2 }"
          >
            <td class="px-6 py-2 text-left">
              <input
                type="checkbox"
                name="select-all"
                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                v-model="selections"
                :value="row.id"
              />
            </td>
            <slot :row="row" :index="row_index"></slot>
          </tr>
          <tr v-if="!rows.length">
            <td :colspan="columns.length">
              <div class="text-center py-12">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vector-effect="non-scaling-stroke"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 class="mt-2 text-sm font-medium text-gray-900">No {{ newButton.label }}</h3>
                <p class="mt-1 text-sm text-gray-500">
                  Get started by creating a new {{ newButton.label }}.
                </p>
                <div class="mt-6">
                  <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click.prevent="setModal({ show: true, component: newButton.component })"
                  >
                    <!-- Heroicon name: solid/plus -->
                    <svg
                      class="-ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    New {{ newButton.label }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import http from '@/utils/http'
import DataTableActions from '@/components/DataTableActions'

export default {
  name: 'DataTable',
  components: {
    DataTableActions,
  },
  props: {
    columns: {
      required: true,
    },
    endpoint: {
      required: true,
    },
  },
  data() {
    return {
      checked: false,
      selections: [],
      rows: [],
      action: 'get',
      options: [
        {
          label: 'Update Selected',
          callback: () => {},
          event: 'put',
        },
        {
          label: 'Delete Selected',
          callback: () => {},
          event: 'delete',
        },
      ],
    }
  },
  mounted() {
    this.init()

    this.$bus.$on('reload-data-table', this.init)
  },
  beforeDestroy() {
    this.$bus.$off('reload-data-table', this.init)
  },
  methods: {
    init() {
      http
        .get(this.endpoint)
        .then(response => {
          if (response.status === 200) {
            this.rows = response.data
            this.rows = _.map(this.rows, row => ({ ...row, status: 'gray' }))
            this.context = [...Array.from(Object.keys(response.data), () => false)]
            this.$emit('input', this.context)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    event(action) {
      this.action = action
      this.setModal({ show: true, component: action, callback: this.update })
    },
    update() {
      const ids = this.selections.join(',')

      ids &&
        http[this.action](`${this.endpoint}?ids=${ids}`).then(() => {
          this.selections = []
          this.checked = false

          this.init()
        })
      this.setModal({ show: false })
    },
  },
  watch: {
    checked: function (flag) {
      if (flag) this.selections = _.map(this.rows, 'id')
      else this.selections = []
    },
  },
}
</script>

<style></style>
