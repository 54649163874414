<template>
  <div v-away="close" class="my-1 relative">
    <button
      type="button"
      class="bg-white relative w-full border border-gray-300 rounded shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none sm:text-sm"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      @click.prevent="context = enabled ? context : !context"
    >
      <span class="block truncate px-3"> Select Action </span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
        <svg
          class="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>
    <ul
      v-show="context && options"
      class="absolute shadow-sm z-10 w-full bg-white max-h-60 rounded py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      aria-activedescendant="listbox-option-3"
    >
      <li
        class="text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9 hover:bg-gray-200"
        role="option"
        v-for="(option, index) in options"
        :key="`option-${index}`"
      >
        <a href="#" @click.prevent="emit(option)" class="font-normal block truncate">{{
          option.label
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DataTableActions',
  props: ['options', 'enabled'],
  data() {
    return {
      context: false,
    }
  },
  methods: {
    emit(option) {
      this.$emit('event', option.event)
      this.close()
    },
    close() {
      this.context = false
    },
  },
}
</script>

<style></style>
